<script setup lang="ts">
import { AxiosError } from 'axios'
import type { NovaBoxUserUnitProfileActionsProps } from './NovaBoxUserUnitProfileActions.types'

const props = defineProps<NovaBoxUserUnitProfileActionsProps>()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
// const router = useRouter()
const commStore = useCommStore()
const mypageStore = useMyPageStore()
const { userStore, showSignInDialog } = useMembershipProcess()
const { showFollowErrorMessage, showUnfollowErrorMessage } = useFollow()
const isFollowing = computed(() => props.followFlag === '1')
const isLoading = ref(false)
// const applyCreator = computed(() => {
//   const status = mypageStore.applyCreatorStatus
//   return {
//     label: t(`applyCreator.guide.apply.status.${status}`),
//     disabled: status !== 'available',
//     showLink: status !== 'approved',
//   }
// })

const handleOnToggleFollow = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  const doToggleFollow = async (
    toggleAction: () => Promise<void>,
    type: 'follow' | 'unfollow'
  ) => {
    try {
      await toggleAction()

      if (mypageStore.homeProfile) {
        switch (type) {
          case 'follow':
            mypageStore.homeProfile.followerCount += 1
            break
          case 'unfollow':
            mypageStore.homeProfile.followerCount -= 1
            break
          default:
            break
        }
      }
    } catch {}
  }
  if (mypageStore.homeProfile!.followFlag === '1') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '팔로잉 버튼 클릭',
      eventLabel: t(isFollowing ? 'following' : 'follow'),
      eventSlot: '유저 홈 > 프로필 영역 > 언팔로우',
      eventI18nAddr: useKoreanTranslation(isFollowing ? 'following' : 'follow'),
      eventComponent: 'Button',
      UnFollowUserSn: props.userSn,
    })
  } else {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '팔로우 버튼 클릭',
      eventLabel: t(isFollowing ? 'following' : 'follow'),
      eventSlot: '유저 홈 > 프로필 영역 > 팔로우',
      eventI18nAddr: useKoreanTranslation(isFollowing ? 'following' : 'follow'),
      eventComponent: 'Button',
      followUserSn: props.userSn,
    })
  }
  mypageStore.homeProfile?.followFlag === '0'
    ? await doToggleFollow(() => handleOnFollow(), 'follow')
    : await doToggleFollow(() => handleOnUnFollow(), 'unfollow')
}

// 팔로우
const handleOnFollow = async () => {
  try {
    isLoading.value = true
    await Promise.all([
      await useSleeper(),
      await commStore.reqFollow({ flwUserSn: props.userSn }),
    ])
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '팔로우',
      eventLabel: t(isFollowing ? 'following' : 'follow'),
      eventSlot: '유저 홈 > 프로필 영역 > 팔로우',
      eventI18nAddr: useKoreanTranslation(isFollowing ? 'following' : 'follow'),
      eventComponent: 'Button',
      flwUserSn: props.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showFollowErrorMessage(err, props.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

// 팔로잉 취소
const handleOnUnFollow = async () => {
  try {
    isLoading.value = true

    await Promise.all([
      await useSleeper(),
      await commStore.reqUnfollow({
        flwUserSn: props.userSn,
      }),
    ])
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '언팔로우',
      eventLabel: t(isFollowing ? 'following' : 'follow'),
      eventSlot: '유저 홈 > 프로필 영역 > 언팔로우',
      eventI18nAddr: useKoreanTranslation(isFollowing ? 'following' : 'follow'),
      eventComponent: 'Button',
      UnFlwUserSn: props.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showUnfollowErrorMessage(err, props.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

// 크리에이터 신청하기
// const handleOnGoApplyCreator = () => {
//   if (mypageStore.applyCreatorStatus !== 'available') return
//
//   router.push({
//     path:
//       locale.value === 'ko'
//         ? '/apply-creator'
//         : `/${locale.value}/apply-creator`,
//   })
// }
</script>

<template>
  <div class="profile-actions">
    <NovaButtonText
      v-if="userStore.user?.userSn !== userSn"
      :on="isFollowing"
      :label="$t(isFollowing ? 'following' : 'follow')"
      :theme="'sky-blue'"
      :responsive-size="{ desktop: 40, mobile: 32 }"
      :loading="isLoading"
      class="action"
      @click.stop="handleOnToggleFollow"
    />
    <!-- todo: 23-07-04 숨김 -->
    <!--    <NovaButtonText
      v-if="userStore.user?.userSn === userSn && applyCreator.showLink"
      :label="`${$t('applyCreator.entry')}`"
      :theme="'sky-blue'"
      :responsive-size="{ desktop: 40, mobile: 32 }"
      :disabled="applyCreator.disabled"
      class="action"
      @click.stop="handleOnGoApplyCreator"
    />-->
  </div>
</template>

<style lang="scss" scoped>
.profile-actions {
  display: flex;
  gap: 16px;
}
</style>
