<script setup lang="ts">
import { AxiosError } from 'axios'
import type {
  UserContentType,
  NovaBoxUserTopHeaderEmits,
  NovaBoxUserTopHeaderProps,
} from './NovaBoxUserTopHeader.types'
import { LayerType, BlockingTarget, type ProfilePayload } from '@store'
import type { TabData } from '@components/NovaTab/NovaTab.types'
import type { HomeActionProps } from '@components/NovaBoxHomeAction/NovaBoxHomeAction.types'
import { NovaModalDialog } from '#components'
import { useCheckEmpty } from '@composables/useCheckEmpty'

const emit = defineEmits<NovaBoxUserTopHeaderEmits>()
const props = withDefaults(defineProps<NovaBoxUserTopHeaderProps>(), {
  isShowTabMenu: true,
})
const route = useRoute()
const { show: modalShow, hide: modalHide } = useModal()
const { userStore } = useMembershipProcess()
const mypageStore = useMyPageStore()
const layoutStore = useLayoutStore()
const appStore = useAppStore()
const { checkPermission, requestPermission } = useAppPermission()
const isOpen = ref(true)
const { gtEvent } = useGoogleTag()

const isEdit = computed(
  () => userStore.user?.userSn === props.homeProfile.userSn
)
const { t } = useI18n()
const state = reactive({
  nameInput: mypageStore.profile?.userNcnm || '',
  textInput: mypageStore.profile?.proflDc || '',
  isNickname: false,
  isText: false,
})
const isEqualUser = computed(
  () => props.homeProfile.userSn === mypageStore.profile?.userSn
)
const homeActionParams = computed(() => {
  const profile = mypageStore.homeProfile
  const params: HomeActionProps | null = profile
    ? {
        target: BlockingTarget.USER,
        userSn: profile.userSn,
        userNcnm: profile.userNcnm,
        isDonation: false,
        blockingAt: profile.blockingAt,
        followerAt: profile.followerAt,
      }
    : null
  return params
})

const onChangeTab = (tabId: TabData<UserContentType>) => {
  emit('onChangeTab', tabId.id)
}

const onDeleteProfile = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 사진 삭제 버튼 클릭',
    eventLabel: 'close-compact',
    eventSlot: '프로필 이미지 삭제 아이콘',
    eventI18nAddr: 'close-compact',
    eventComponent: 'Button',
  })
  await uploadImageDialogModal(
    t('mypage.profile.dialog.title'),
    t('mypage.profile.dialog.initImage')
  )
}

const onModifyProfile = async () => {
  // 앱 일때 스토리지 권한 체크
  if (appStore.isApp) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'profileImage',
      })
      if (!accessAble) return
    }
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 이미지 변경 버튼 클릭',
    eventLabel: 'setting',
    eventSlot: '프로필 이미지 변경 버튼',
    eventI18nAddr: 'setting',
    eventComponent: 'Button',
  })
  try {
    const { formData } = await useGetFiles({
      accept: 'image/*',
      multiple: false,
      formDataName: 'profileImg',
    })
    await mypageStore.createProfilePhoto(formData)
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 2005:
          useToast(t('mypage.profile.toastMessage.imgError2005'))
          break
        default:
          useToast(t('mypage.profile.toastMessage.imgError'))
          break
      }
    }
  }
}

const onCancelName = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 취소 버튼 클릭',
    eventLabel: t('mypage.profile.button.cancel'),
    eventSlot: '닉네임 변경 취소',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.cancel'),
    eventComponent: 'Button',
  })
  state.nameInput = ''
  state.isNickname = false
}

const onSaveName = async () => {
  const isUnavailableNickname = useCheckUrlSpecialCharacter(state.nameInput)
  const isEmpty = useCheckEmpty(state.nameInput)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 > 저장 버튼 클릭',
    eventLabel: t('mypage.profile.button.save'),
    eventSlot: '닉네임 변경',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
    eventComponent: 'Button',
  })
  if (isEmpty) {
    useToast(t('mypage.profile.placeholder.nickname'))
    state.nameInput = ''
    return
  }
  if (isUnavailableNickname) {
    useToast(t('mypage.profile.toastMessage.unavailableNickname'))
    return
  }
  try {
    const payload: ProfilePayload = {
      proflDc: mypageStore.profile!.proflDc,
      userNcnm: state.nameInput,
    }
    await mypageStore.editProfile(payload)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '닉네임 변경',
      eventLabel: t('mypage.profile.button.save'),
      eventSlot: '닉네임 변경',
      eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
      eventComponent: 'Button',
      userNcnm: payload.userNcnm,
    })
    state.isNickname = false
    // 사용자 닉네임 변경이라면 라우터 리플레이스
    if (String(route.params.id) !== payload.userNcnm) {
      const query = route.query
      const currentRoute = useGetRouteConst(String(route.name))
      if (currentRoute) {
        useNavigations({
          url: useRoutePathIdChange(currentRoute.path, {
            id: payload.userNcnm,
          }),
          query,
          type: 'replace',
        })
      }
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case 2010:
          await showDialogModal(
            t('mypage.profile.dialog.title'),
            t('mypage.profile.dialog.doubleNickname')
          )
          state.nameInput = ''
          break
        default:
          useToast(t('commonError.network.message'))
          break
      }
    }
  }
}

const onCancelText = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 내용 변경 취소 버튼 클릭',
    eventLabel: t('mypage.profile.button.cancel'),
    eventSlot: '프로필 내용 변경',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.cancel'),
    eventComponent: 'Button',
  })
  state.textInput = ''
  state.isText = false
}

const onSaveText = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 내용 변경 저장 버튼 클릭',
    eventLabel: t('mypage.profile.button.save'),
    eventSlot: '프로필 내용 변경',
    eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
    eventComponent: 'Button',
  })
  try {
    const payload: ProfilePayload = {
      proflDc: state.textInput,
      userNcnm: mypageStore.profile!.userNcnm,
    }
    await mypageStore.editProfile(payload)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '프로필 내용 변경',
      eventLabel: t('mypage.profile.button.save'),
      eventSlot: '프로필 내용 변경',
      eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
      eventComponent: 'Button',
      proflDc: state.textInput,
    })
    state.isText = false
  } catch (err) {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        default:
          useToast(t('commonError.network.message'))
          break
      }
    }
  }
}

const showDialogModal = (title: string, content: string) => {
  return modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
      ],
    },
    slots: {
      title,
      content,
    },
  })
}

const uploadImageDialogModal = async (title: string, content: string) => {
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_UPLOAD_IMAGE,
      btns: [
        {
          label: t('cancel'),
          theme: 'transparent',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '프로필 이미지 삭제 > 취소 버튼 클릭',
              eventLabel: t('cancel'),
              eventSlot: '프로필 이미지 삭제 모달',
              eventI18nAddr: useKoreanTranslation('cancel'),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_UPLOAD_IMAGE)
            return false
          },
        },
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '프로필 이미지 삭제 > 확인 버튼 클릭',
              eventLabel: t('mypage.profile.button.save'),
              eventSlot: '프로필 삭제 모달',
              eventI18nAddr: useKoreanTranslation('mypage.profile.button.save'),
              eventComponent: 'Button',
            })
            try {
              await mypageStore.deleteProfilePhoto()
              await modalHide(modalsName.MODAL_UPLOAD_IMAGE)
            } catch (err: any) {
              const errorData = err.response
              if (errorData.status === 2004) {
                await showDialogModal(
                  t('mypage.profile.dialog.title'),
                  t('mypage.profile.dialog.noImage')
                )
                state.nameInput = ''
              }
            }
          },
        },
      ],
    },
    slots: {
      title,
      content,
    },
  })
}

const handleOnChangeUserNcnm = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '닉네임 변경 버튼 클릭',
    eventLabel: 'write',
    eventSlot: '닉네임 변경',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  state.isNickname = true
  state.nameInput = mypageStore.profile?.userNcnm || ''
}
const handleOnChangeUserDescription = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '프로필 내용 변경 버튼 클릭',
    eventLabel: 'write',
    eventSlot: '프로필 내용 변경',
    eventI18nAddr: 'write',
    eventComponent: 'Button',
  })
  state.isText = true
  state.textInput = mypageStore.profile?.proflDc || ''
}
const openAccountSetting = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '마이 홈 > 프로필 수정 버튼 클릭',
    eventLabel: 'more-vertical',
    eventSlot: '프로필 수정 열기',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  layoutStore['layer:open'](LayerType.USER_PROFILE_EDIT_PANEL)
}
</script>

<template>
  <div :class="['top-section', { off: !isOpen }]">
    <NovaImageContainer
      :key="homeProfile?.backgroundImage"
      :image-url="homeProfile?.backgroundImage"
      :ratio="'5:1'"
      :empty-type="'none'"
      class="top-section-bg"
    />

    <div class="profile-wrap">
      <div class="option-tool">
        <span v-if="!isOpen" class="title">
          {{ t('mypage.profile.title') }}
        </span>
      </div>

      <div v-if="isOpen" class="profile-area">
        <div class="profile-box">
          <NovaButtonIcon
            v-if="isEqualUser && mypageStore.profile?.userAvataUseAt === 'N'"
            :theme="'transparent'"
            :icon="{ type: 'outline', name: 'close-compact' }"
            :size="24"
            class="btn-delete"
            @click="onDeleteProfile"
          />

          <NovaPortraitContainer
            class="profile"
            :size="'xl'"
            :stroke="true"
            :image-url="
              isEqualUser
                ? mypageStore.profile?.userProflUrl
                : homeProfile.userProflUrl
            "
          />

          <div v-if="isEqualUser" class="btn-box">
            <NovaButtonIcon
              :theme="'square-bg'"
              :icon="{ type: 'outline', name: 'setting' }"
              :size="24"
              :fill-icon="true"
              class="btn-edit"
              @click="onModifyProfile"
            />
          </div>
        </div>

        <div class="nickname-box">
          <span v-if="!state.isNickname">
            <span class="text">
              {{
                !isEqualUser
                  ? homeProfile.userNcnm
                  : mypageStore.profile?.userNcnm
              }}
            </span>

            <NovaButtonIcon
              v-if="isEqualUser"
              :theme="'transparent'"
              :icon="{ type: 'outline', name: 'write' }"
              :size="14"
              class="btn-edit"
              @click="handleOnChangeUserNcnm"
            />
          </span>

          <span v-if="state.isNickname" class="edit-box">
            <NovaInput
              v-model="state.nameInput"
              class="nickname"
              :placeholder="t('mypage.profile.placeholder.nickname')"
              :max-length="25"
            />

            <span class="action-box">
              <NovaButtonText
                :label="t('mypage.profile.button.cancel')"
                :theme="'secondary-gray'"
                :size="32"
                class="btn-action"
                @click="onCancelName"
              />

              <NovaButtonText
                :label="t('mypage.profile.button.save')"
                :theme="'primary-blue-light'"
                :size="32"
                :disabled="state.nameInput.length <= 0"
                class="btn-action"
                @click="onSaveName"
              />
            </span>
          </span>
        </div>

        <div class="content-box">
          <span v-if="!state.isText">
            <span class="text">
              {{
                !isEqualUser
                  ? homeProfile.proflDc
                  : mypageStore.profile?.proflDc || '-'
              }}
            </span>

            <NovaButtonIcon
              v-if="isEqualUser"
              :theme="'transparent'"
              :icon="{ type: 'outline', name: 'write' }"
              :size="14"
              class="btn-edit"
              @click="handleOnChangeUserDescription"
            />
          </span>

          <span v-if="state.isText" class="edit-box">
            <NovaInput
              v-if="state.isText"
              v-model="state.textInput"
              :placeholder="t('mypage.profile.placeholder.content')"
              :max-length="30"
            />

            <span class="action-box">
              <NovaButtonText
                :label="t('mypage.profile.button.cancel')"
                :theme="'secondary-gray'"
                :size="32"
                class="btn-action"
                @click="onCancelText"
              />

              <NovaButtonText
                :label="t('mypage.profile.button.save')"
                :theme="'primary-blue-light'"
                :size="32"
                class="btn-action"
                @click="onSaveText"
              />
            </span>
          </span>
        </div>

        <NovaBoxUserUnitSummery
          v-if="!isShowBlockMessage"
          :user-sn="homeProfile.userSn"
          :followings="homeProfile.followingCount"
          :followers="homeProfile.followerCount"
          :coins="isEqualUser ? homeProfile.lmAmount || '0' : undefined"
          :is-equal-user="isEqualUser"
          class="user-summery"
        />

        <NovaBoxUserUnitProfileActions
          v-if="!isShowBlockMessage"
          :follow-flag="homeProfile.followFlag"
          :user-sn="homeProfile.userSn"
          :user-ncnm="homeProfile.userNcnm"
          class="profile-actions"
        />
      </div>
    </div>

    <NovaBoxHomeAction
      v-if="!isEqualUser && homeActionParams"
      :source="homeActionParams"
      class="home-action"
    />

    <!-- 사용자 차단 안내 -->
    <slot name="blockContents" />

    <!-- 포스트, 리워드, 후원, 내 활동 탭 -->
    <div v-if="isShowTabMenu" class="menu-box">
      <NovaTab
        :tab-value="tabs"
        :init-tab="initTab"
        :align="'center'"
        :horizon-padding="20"
        @on-change-tab="onChangeTab"
      />
    </div>

    <div v-if="isEdit" class="header-floating-button">
      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'more-vertical' }"
        :size="20"
        @click="openAccountSetting"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-section {
  flex-shrink: 0;
  position: relative;
  padding: 13px 20px 0;
  border-radius: 16px;
  overflow: hidden;
  background-color: $color-white;

  :deep(.top-section-bg) {
    &.is-load-success {
      background: inherit;
    }
  }

  .top-section-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 245px;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(
      97.77deg,
      $color-secondary-blue-light-80 -5.67%,
      $color-highlight 126.11%
    );
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 245px;
      border-radius: 16px 16px 0 0;
    }
    &::after {
      background: hex-to-rgba($color-bg-custom-7, 0.3);
    }
  }

  &.off {
    &::before,
    &::after {
      height: 50px;
    }

    .profile-wrap {
      max-height: 100px;
    }
    .btn-open {
      transform: rotate(180deg);
    }

    .option-tool {
      justify-content: space-between;
    }
  }
  .btn-options {
    width: 20px;
    height: 20px;
    background-image: url('assets/images/icon-head-options.png');
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.profile-wrap {
  position: relative;
  z-index: 10;
  @include transition(max-height 0.3s);
}

.option-tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-white;

  .title {
    @include text-style($text-heading2-bold);
  }

  .option-button {
    display: flex;
    gap: 8px;
  }

  .btn-open {
    @include transition(transform 0.5s);
  }
}

.profile-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  padding-top: 145px;
  padding-bottom: 30px;
  color: $color-text-4;
  opacity: 1;
  @include transition(opacity 0.15s ease-in-out);

  .profile-box {
    position: relative;
    padding: 0 12px;

    .btn-box,
    .btn-delete {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .btn-delete {
      color: $color-white;
      top: 0;
      bottom: auto;
    }
    .btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;

      .input-file {
        width: 0;
        height: 0;
        visibility: hidden;
      }
    }
  }

  .profile-control {
    margin-top: 10px;
  }

  .nickname-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 80%;
    .text {
      @include text-style($text-heading1-bold);
      color: $color-text-2;
      word-break: break-all;
    }

    .btn-edit {
      margin-left: 8px;
    }
  }

  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 80%;

    .text {
      color: $color-text-2;
    }
    .btn-edit {
      margin-left: 8px;
    }
  }

  .edit-box {
    width: 100%;
    .action-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 10px;
    }
  }

  .user-summery {
    margin-top: 24px;
  }

  .profile-actions {
    margin-top: 24px;
  }
}

.header-floating-button {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 12;
}

.home-action {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  z-index: 12;
}

.menu-box {
  border-top: 1px solid hex-to-rgba($color-black, 0.06);
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
}

@include mobile {
  .menu-box {
    :deep(.tabs .swiper-wrapper) {
      justify-content: flex-start !important;
    }
  }
}
</style>
